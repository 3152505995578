
 
    .layout {
     padding-left: 16px;
     padding-right: 16px;
    }
 
@media (min-width: 768px) {
    .layout-md {
     padding-left: 24.5px;
     padding-right: 24.5px;
    }
}

@media (min-width: 1400px) {
    .layout-xl {
     padding-left: 100px;
     padding-right: 100px;
    }
}

@media (min-width: 1100px) {
    .layout-lg {
       padding-left: 76px;
       padding-right: 76px;
    }
}