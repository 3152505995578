/* src/index.css */
/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/styles/tailwind.css */
@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");
/* src/styles/tailwind.css */
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html {
    scroll-behavior: smooth;
  }
  

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
    background-color: white;
}
.font-michroma {
    font-family: "Michroma", sans-serif;
}
.font-lexend {
    font-family: "Inter", sans-serif;
}
.font-mukta {
    font-family: "Inter", sans-serif;
}
.font-inter {
    font-family: "Inter", sans-serif;
}
 

 
/* Styling for Gradient Border (Salon Offers )  */
.gradBorder {
 
border: 1px solid var(--Golden-Crust, #BF963E);
 
}

/* To Hide Scroll Bar */
.hideScrollBar {
    scrollbar-width: none;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

/* To Hide input:number type behaviour  */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Custom Dots for Carousel */

.review-carousel-container .carousel .control-dots .indicator {
    background: #ffffff; 
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: inline-block;
    margin: -9px 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .review-carousel-container .carousel {
    position: relative !important; 
    transition: transform 0.5s ease-in-out;
  }
  
  .review-carousel-container .carousel .control-dots .indicator.selected {
    background: linear-gradient(to right,   #BF963E 7%, #FFFBA4 47%, #AD832D 87%);
    width: 20px;
    border-radius: 18px;
  }
  
 /* Custom CSS to move toast notifications below the navbar */
.toast-container {
    position: fixed;
    top: 100px; /* Adjust this value as needed */
    right: 20px;
    z-index: 9999; /* Ensure toast notifications appear above other content */
}

/* Block the Scroll when the Pops are active */
.no-scroll {
    overflow: hidden;
    height: 100%;
  }
  
  .gradient-border {
    position: relative;
    border-radius: 10px;
    background: #fff; /* Adjust the background color as needed */
    z-index: 1;
  }
  .gradient-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    padding: 1px; /* Adjust this value for border width */
    background: linear-gradient(90deg, #BF963E 7%, #FFFBA4 47%, #AD832D 87%);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
    z-index: -1;
  }

  /* Star Blink animation  */

  .animate-blink {
    animation: blink 0.5s infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  /* Spinner Animation */
  .wheel {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 8px solid #444;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: conic-gradient(
      #f44336 0deg 60deg,
      #ffeb3b 60deg 120deg,
      #4caf50 120deg 180deg,
      #2196f3 180deg 240deg,
      #ff9800 240deg 300deg,
      #9c27b0 300deg 360deg
    );
  }
  
 
  
  .wheel-section {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 50%;
    transform-origin: 0% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .wheel-text {
    font-size: 14px;
    color: white;
    transform-origin: center;
  }


  /* For Logo Spinner */
  .loader-circle {
    
    border: 2px solid #ccc; /* adjust the color and thickness of the loader circle */
    border-top: 4px solid #333; /* adjust the color of the loader circle */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  
  /* ------ Carousel For Hero Section ------------ */

 
  .carousel-home {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .carousel-image {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: left 1s ease-in-out;
    z-index: 0;
  }
  
  .carousel-image.active {
    left: 0;
    z-index: 1;
  }
  
  .carousel-image.previous {
    left: -100%;
    z-index: 0;
  }
  
  /* ------------ Carousel for Services ------------- */